var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "particulars",
      class: [_vm.theme == "common" ? "" : "dark"],
    },
    [
      _c("div", { staticClass: "activeName_big_box" }, [
        _c("div", { staticClass: "part1 part" }, [
          _c("div", { staticClass: "top_account" }, [
            _c("img", {
              staticClass: "imgs",
              attrs: {
                src: "http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715934366570_ellipse.png",
                alt: "",
              },
            }),
            _c("span", { staticClass: "Account" }, [
              _vm._v(_vm._s(_vm.$t("wallet.account"))),
            ]),
          ]),
          _vm.displayCredits
            ? _c("div", { staticClass: "creditsBox" }, [
                _c("p", [_vm._v(_vm._s(_vm.$t("wallet.TheseUcredits")))]),
                _c("p", [_vm._v(_vm._s(_vm.$t("wallet.UcreditsOrder")))]),
                _c("p", [_vm._v(_vm._s(_vm.$t("wallet.fulfillingOrders")))]),
              ])
            : _vm._e(),
          _c("div", { staticClass: "wallet_box_money" }, [
            _c("div", { staticClass: "sum" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("wallet.sum")))]),
              _c("span", [_vm._v(_vm._s(_vm.money1) + ".")]),
              _c("span", [_vm._v(_vm._s(_vm.money2))]),
            ]),
            _c("div", { staticClass: "Ucredits" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("wallet.Ucredit")))]),
              _c("span", [
                _vm._v("U" + _vm._s(_vm.CreditCoinArray.CreditCoin)),
              ]),
              _c(
                "span",
                { on: { mouseenter: _vm.enter, mouseleave: _vm.leave } },
                [_c("i", { staticClass: "el-icon-question" })]
              ),
            ]),
            _c("div", { staticClass: "credit_limit" }, [
              _c("span", [_vm._v("Available credit: ")]),
              _c("span", [
                _vm._v("$ " + _vm._s(_vm.CreditCoinArray.CreditsBalance)),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-tooltip",
                {
                  staticClass: "item",
                  attrs: {
                    effect: "dark",
                    content:
                      "Can only withdraw after paid all orders of collaborative payment gateway",
                    placement: "top-start",
                  },
                },
                [
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.paymentList.AllowBalanceWithdrawal,
                          expression: "paymentList.AllowBalanceWithdrawal",
                        },
                      ],
                      staticClass: "OpenWithdrawal comBtn col-col0",
                      on: { click: _vm.OpenWithdrawalDialogBtn },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("wallet.Payouts")) + " ")]
                  ),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "rechargeBtn comBtn col-col0",
                  on: { click: _vm.recharge },
                },
                [_vm._v(" " + _vm._s(_vm.$t("wallet.recharge")) + " ")]
              ),
              _c(
                "button",
                {
                  staticClass: "recordsBtn comBtn col-col0",
                  on: { click: _vm.records },
                },
                [_vm._v(" " + _vm._s(_vm.$t("wallet.exoenditure")) + " ")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "part part2" },
          _vm._l(_vm.payList, function (payItem, payIndex) {
            return _c(
              "div",
              { key: payIndex, staticClass: "flex" },
              [
                _c(
                  "el-checkbox",
                  {
                    on: {
                      change: function ($event) {
                        return _vm.changePay(payIndex, $event)
                      },
                    },
                    model: {
                      value: payItem.checked,
                      callback: function ($$v) {
                        _vm.$set(payItem, "checked", $$v)
                      },
                      expression: "payItem.checked",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card",
                        class: payItem.checked ? "choosed" : "",
                      },
                      [
                        _c("img", {
                          attrs: { src: _vm.cards[payItem.PayType], alt: "" },
                        }),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "part part3" },
          [
            _vm.payList && _vm.payList.length > 0
              ? [
                  _vm.selectPayType != 11
                    ? _c("div", { staticClass: "item PaymentAmount" }, [
                        _c("div", { staticClass: "left" }, [
                          _vm._v(_vm._s(_vm.$t("wallet.Payment"))),
                        ]),
                        _c("div", { staticClass: "right price-list" }, [
                          _c("div", { staticClass: "price-list" }, [
                            _c("span", [_vm._v("$")]),
                            _c("input", {
                              staticClass: "money",
                              attrs: { type: "number", maxlength: "7" },
                              domProps: { value: _vm.payMoney },
                              on: { keyup: _vm.setMin },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "item" }, [
                    _vm.selectPayType != 11
                      ? _c("div", { staticClass: "left" }, [
                          _vm.selectPayType == 0
                            ? _c("span", [
                                _vm._v(
                                  _vm._s(_vm.$t("wallet.BankTransferFee"))
                                ),
                              ])
                            : _vm._e(),
                          _vm.selectPayType == 1
                            ? _c("span", [_vm._v(_vm._s(_vm.$t("wallet.Fee")))])
                            : _vm._e(),
                          _vm.selectPayType == 3
                            ? _c("span", [
                                _vm._v(_vm._s(_vm.$t("wallet.HandlingFee"))),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm.selectPayType != 11
                      ? _c("div", { staticClass: "right" }, [
                          _vm.isFree
                            ? _c("span", { staticClass: "selectedDatr" }, [
                                _vm._v(_vm._s(_vm.$t("wallet.FREE"))),
                              ])
                            : _c("span", { staticClass: "right" }, [
                                _c("span", [_vm._v("$")]),
                                _c("span", [_vm._v(_vm._s(_vm.fee))]),
                              ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm.selectPayType != 11
                    ? _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "left bold" }, [
                          _vm._v(_vm._s(_vm.$t("wallet.TotalAmount"))),
                        ]),
                        _c("div", { staticClass: "right" }, [
                          _c("span", [_vm._v("$")]),
                          _c("span", [_vm._v(_vm._s(_vm.totalAmount))]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.selectPayType == 0
                    ? _c("div", { staticClass: "voucher-center" }, [
                        _c("p", [_vm._v(_vm._s(_vm.$t("wallet.amount")))]),
                      ])
                    : _vm._e(),
                  _vm.selectPayType != 11
                    ? _c("div", { staticClass: "CheckAgreement" }, [
                        _c(
                          "p",
                          {
                            staticClass: "agree",
                            staticStyle: { "margin-top": "5px" },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: { type: "checkbox" },
                              model: {
                                value: _vm.checked,
                                callback: function ($$v) {
                                  _vm.checked = $$v
                                },
                                expression: "checked",
                              },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("register.agree")) + " "
                            ),
                            this.formLink === "vn.usadrop.com"
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "Agree1",
                                      attrs: {
                                        href: "https://vn.usadrop.com/terms-and-conditions/",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("register.user")))]
                                  ),
                                ])
                              : _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "Agree1",
                                      attrs: {
                                        href: "https://usadrop.com/terms-and-conditions/",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("register.user")))]
                                  ),
                                ]),
                            _vm._v(" " + _vm._s(_vm.$t("register.and")) + " "),
                            this.formLink === "vn.usadrop.com"
                              ? _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "Agree1",
                                      attrs: {
                                        href: "https://vn.usadrop.com/privacy-policy/",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("register.privacy")))]
                                  ),
                                ])
                              : _c("span", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "Agree1",
                                      attrs: {
                                        href: "https://usadrop.com/privacy-policy/",
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("register.privacy")))]
                                  ),
                                ]),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.paymentList.IsAdmin == true && _vm.selectPayType != 11
                    ? _c("div", { staticClass: "payBtn" }, [
                        _c(
                          "button",
                          {
                            staticClass: "comBtn btn-bg0",
                            on: {
                              click: function ($event) {
                                return _vm.confirm(_vm.selectPayType)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("wallet.Confirm")) + " ")]
                        ),
                      ])
                    : _vm._e(),
                  _vm.selectPayType === 11
                    ? _c("div", [
                        _c("div", { on: { click: _vm.ToFaq } }, [
                          _c("div", { staticClass: "pleaseagent_word" }, [
                            _c("p", [
                              _vm._v(_vm._s(_vm.$t("wallet.PleaseAgent"))),
                            ]),
                          ]),
                          _c("div", { staticClass: "pleaseagent_img" }, [
                            _c("img", {
                              attrs: {
                                src: _vm.baseApiHost() + _vm.CenterList.Avatar,
                                alt: "",
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              : _vm._e(),
          ],
          2
        ),
      ]),
      _vm.visa
        ? _c("div", { staticClass: "paybox" }, [
            _c("div", { staticClass: "pay_content" }, [
              _c("p", { staticClass: "pay_header_title" }, [
                _vm._v(_vm._s(_vm.$t(""))),
                _c("span", { on: { click: _vm.off } }),
              ]),
              _c("div", { staticClass: "pay_items" }, [
                _c("div", { staticClass: "account-information" }, [
                  _vm._v(_vm._s(_vm.$t("wallet.upload"))),
                ]),
                _c("div", { staticClass: "pay_item_word" }, [
                  _c("div", { staticClass: "pay_left" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.bank"))),
                  ]),
                  _c("div", { staticClass: "pay_right" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.First"))),
                  ]),
                ]),
                _c("div", { staticClass: "pay_item_word" }, [
                  _c("div", { staticClass: "pay_left" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.swift"))),
                  ]),
                  _c("div", { staticClass: "pay_right" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.czcbcn2x"))),
                  ]),
                ]),
                _c("div", { staticClass: "pay_item_word" }, [
                  _c("div", { staticClass: "pay_left" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.beneficiary"))),
                  ]),
                  _c("div", { staticClass: "pay_right" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.zhejiang"))),
                  ]),
                ]),
                _c("div", { staticClass: "pay_item_word" }, [
                  _c("div", { staticClass: "pay_left" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.zipcode"))),
                  ]),
                  _c("div", { staticClass: "pay_right" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.sum2"))),
                  ]),
                ]),
                _c("div", { staticClass: "pay_item_word" }, [
                  _c("div", { staticClass: "pay_left" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.swidt"))),
                  ]),
                  _c("div", { staticClass: "pay_right" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.bofaus3n"))),
                  ]),
                ]),
                _c("div", { staticClass: "pay_item_word" }, [
                  _c("div", { staticClass: "pay_left" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.SWIFTCode"))),
                  ]),
                  _c("div", { staticClass: "pay_right" }, [
                    _vm._v(_vm._s(_vm.$t("wallet.MCBEUS33"))),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "transfer" }, [
                _c("div", { staticClass: "slip" }, [
                  _vm._v(_vm._s(_vm.$t("wallet.submit"))),
                ]),
                _c("div", { staticClass: "price" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("wallet.Amount")))]),
                  _c("span", [_vm._v("$" + _vm._s(_vm.payMoney))]),
                ]),
                _c("div", { staticClass: "ImgBigBox" }, [
                  _c(
                    "div",
                    { staticClass: "upload" },
                    [
                      _c(
                        "el-upload",
                        {
                          attrs: {
                            action: _vm.action,
                            "list-type": "picture-card",
                            headers: _vm.headersaa,
                            "on-preview": _vm.handlePictureCardPreview,
                            "on-remove": _vm.handleRemove,
                            "on-success": _vm.handleAvatarSuccess,
                            limit: 3,
                          },
                        },
                        [_c("i", { staticClass: "el-icon-plus" })]
                      ),
                      _c(
                        "el-dialog",
                        {
                          attrs: { visible: _vm.dialogVisible },
                          on: {
                            "update:visible": function ($event) {
                              _vm.dialogVisible = $event
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              width: "100%",
                              src: _vm.dialogImageUrl,
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "ModeBox" }, [
                    _c("div", { staticClass: "ScreenshotTitle" }, [
                      _vm._v(_vm._s(_vm.$t("wallet.ScreenshotExample"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "demo_image__preview" },
                      [
                        _c("el-image", {
                          staticStyle: { width: "150px", height: "150px" },
                          attrs: {
                            src: _vm.url,
                            "preview-src-list": _vm.srcList,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "account" }, [
                  _c("span", [_vm._v(_vm._s(_vm.$t("wallet.Bank1")))]),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.Memo,
                        expression: "Memo",
                      },
                    ],
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("wallet.PleaseMessage"),
                      name: "",
                      id: "",
                    },
                    domProps: { value: _vm.Memo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.Memo = $event.target.value
                      },
                    },
                  }),
                ]),
                _c("div", { staticClass: "message" }),
                _c("div", { staticClass: "btn2" }, [
                  _c(
                    "button",
                    {
                      staticClass: "cancel comBtn border-col5 col-col5",
                      on: { click: _vm.off },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("wallet.cancel")) + " ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "ascertain comBtn btn-bg0",
                      on: { click: _vm.ascertain },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("wallet.SubmitBtn")) + " ")]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.payPal
        ? _c("div", { staticClass: "pay_pal" }, [
            _c(
              "div",
              { staticClass: "pay_content" },
              [
                _c("p", { staticClass: "pay_title" }, [
                  _c("span", { on: { click: _vm.centers } }, [
                    _c("i", { staticClass: "el-icon-close" }),
                  ]),
                ]),
                _c("paypal", {
                  staticStyle: {
                    width: "50px",
                    "margin-top": "-50px",
                    "margin-left": "80px",
                  },
                  attrs: {
                    money: _vm.ptotalAmount,
                    totalmoney: _vm.prechargeAmount,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        [
          _c("AirWallet", {
            ref: "child",
            attrs: { money: _vm.ptotalAmount, totalmoney: _vm.prechargeAmount },
          }),
        ],
        1
      ),
      _vm.OpenWithdrawalDialog
        ? _c("WithdrawalDialog", {
            ref: "withdrawalDialog",
            on: { close: _vm.CloseWithdrawalDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }