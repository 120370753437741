<template>
  <div class="particulars" :class="[theme == 'common' ? '' : 'dark']">
    <div class="activeName_big_box">
      <div class="part1 part">
        <div class="top_account">
          <img
            class="imgs"
            src="http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715934366570_ellipse.png"
            alt="" />
          <span class="Account">{{ $t('wallet.account') }}</span>
        </div>
        <div class="creditsBox" v-if="displayCredits"> <!-- 信用币使用内容 -->
          <p>{{ $t('wallet.TheseUcredits') }}</p>
          <p>{{ $t('wallet.UcreditsOrder') }}</p>
          <p>{{ $t('wallet.fulfillingOrders') }}</p>
        </div>
        <div class="wallet_box_money">
          <div class="sum">
            <span>{{ $t('wallet.sum') }}</span>
            <span>{{ money1 }}.</span>
            <span>{{ money2 }}</span>
          </div>
          <div class="Ucredits">
            <span>{{ $t('wallet.Ucredit') }}</span>
            <span>U{{ CreditCoinArray.CreditCoin }}</span>
            <span @mouseenter="enter" @mouseleave="leave"><i class="el-icon-question"></i></span>
          </div>
          <div class="credit_limit">
            <span>Available credit: </span>
            <span>$ {{ CreditCoinArray.CreditsBalance }}</span>
          </div>
        </div>
        <div class="btn">
          <el-tooltip class="item" effect="dark" content="Can only withdraw after paid all orders of collaborative payment gateway" placement="top-start">
            <button
              @click="OpenWithdrawalDialogBtn"
              class="OpenWithdrawal comBtn col-col0"
              v-show="paymentList.AllowBalanceWithdrawal">
              {{ $t('wallet.Payouts') }}
            </button>
          </el-tooltip>
          <button @click="recharge" class="rechargeBtn comBtn col-col0">
            {{ $t('wallet.recharge') }}
          </button>
          <button @click="records" class="recordsBtn comBtn col-col0">
            {{ $t('wallet.exoenditure') }}
          </button>
        </div>
      </div>
      <div class="part part2">
        <div v-for="(payItem, payIndex) in payList" :key="payIndex" class="flex">
          <el-checkbox @change="changePay(payIndex, $event)" v-model="payItem.checked">
            <div class="card" :class="payItem.checked ? 'choosed' : ''">
              <img :src="cards[payItem.PayType]" alt="" />
            </div>
          </el-checkbox>
        </div>
      </div>
      <div class="part part3">
        <template v-if="payList && payList.length > 0">
          <div class="item PaymentAmount" v-if="selectPayType != 11">
            <div class="left">{{ $t('wallet.Payment') }}</div>
            <div class="right price-list">
              <div class="price-list">
                <span>$</span>
                <input
                  type="number"
                  :value="payMoney"
                  class="money"
                  maxlength="7"
                  @keyup="setMin" />
              </div>
            </div>
          </div>
          <div class="item">
            <div class="left" v-if="selectPayType != 11">
              <span v-if="selectPayType == 0">{{ $t('wallet.BankTransferFee') }}</span>
              <span v-if="selectPayType == 1">{{ $t('wallet.Fee') }}</span>
              <span v-if="selectPayType == 3">{{ $t('wallet.HandlingFee') }}</span>
            </div>
            <div class="right" v-if="selectPayType != 11">
              <span v-if="isFree" class="selectedDatr">{{ $t('wallet.FREE') }}</span>
              <span v-else class="right">
                <span>$</span>
                <span>{{ fee }}</span>
              </span>
            </div>
          </div>
          <div class="item" v-if="selectPayType != 11">
            <div class="left bold">{{ $t('wallet.TotalAmount') }}</div>
            <div class="right">
              <span>$</span>
              <span>{{ totalAmount }}</span>
            </div>
          </div>
          <div class="voucher-center" v-if="selectPayType == 0">
            <p>{{ $t('wallet.amount') }}</p>
          </div>
          <div class="CheckAgreement" v-if="selectPayType != 11">
            <p class="agree" style="margin-top: 5px">
              <el-checkbox v-model="checked" type="checkbox" />
              {{ $t('register.agree') }}
              <span v-if="this.formLink === 'vn.usadrop.com'">
                <a
                  href="https://vn.usadrop.com/terms-and-conditions/"
                  class="Agree1"
                  target="_blank">{{ $t('register.user') }}</a>
              </span>
              <span v-else>
                <a
                  href="https://usadrop.com/terms-and-conditions/"
                  class="Agree1"
                  target="_blank">{{ $t('register.user') }}</a>
              </span>
              {{ $t('register.and') }}
              <span v-if="this.formLink === 'vn.usadrop.com'">
                <a href="https://vn.usadrop.com/privacy-policy/" class="Agree1" target="_blank">{{ $t('register.privacy') }}</a>
              </span>
              <span v-else>
                <a href="https://usadrop.com/privacy-policy/" class="Agree1" target="_blank">{{ $t('register.privacy') }}</a>
              </span>
            </p>
          </div>
          <div class="payBtn" v-if="paymentList.IsAdmin == true && selectPayType != 11">
            <button class="comBtn btn-bg0" @click="confirm(selectPayType)">
              {{ $t('wallet.Confirm') }}
            </button>
          </div>
          <div v-if="selectPayType === 11">
            <div @click="ToFaq">
              <div class="pleaseagent_word">
                <p>{{ $t('wallet.PleaseAgent') }}</p>
              </div>
              <div class="pleaseagent_img">
                <img :src="baseApiHost()+CenterList.Avatar" alt="">
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 填写信息 -->
    <div class="paybox" v-if="visa">
      <div class="pay_content">
        <p class="pay_header_title">{{ $t('') }}<span @click="off"></span></p>
        <div class="pay_items">
          <div class="account-information">{{ $t('wallet.upload') }}</div>
          <div class="pay_item_word">
            <div class="pay_left">{{ $t('wallet.bank') }}</div>
            <div class="pay_right">{{ $t('wallet.First') }}</div>
          </div>
          <div class="pay_item_word">
            <div class="pay_left">{{ $t('wallet.swift') }}</div>
            <div class="pay_right">{{ $t('wallet.czcbcn2x') }}</div>
          </div>
          <div class="pay_item_word">
            <div class="pay_left">{{ $t('wallet.beneficiary') }}</div>
            <div class="pay_right">{{ $t('wallet.zhejiang') }}</div>
          </div>
          <div class="pay_item_word">
            <div class="pay_left">{{ $t('wallet.zipcode') }}</div>
            <div class="pay_right">{{ $t('wallet.sum2') }}</div>
          </div>
          <div class="pay_item_word">
            <div class="pay_left">{{ $t('wallet.swidt') }}</div>
            <div class="pay_right">{{ $t('wallet.bofaus3n') }}</div>
          </div>
          <div class="pay_item_word">
            <div class="pay_left">{{ $t('wallet.SWIFTCode') }}</div>
            <div class="pay_right">{{ $t('wallet.MCBEUS33') }}</div>
          </div>
        </div>
        <div class="transfer">
          <div class="slip">{{ $t('wallet.submit') }}</div>
          <div class="price">
            <span>{{ $t('wallet.Amount') }}</span>
            <span>${{ payMoney }}</span>
          </div>
          <div class="ImgBigBox">
            <div class="upload">
              <el-upload
                :action="action"
                list-type="picture-card"
                :headers="headersaa"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :limit="3">
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
            <div class="ModeBox">
              <div class="ScreenshotTitle">{{ $t('wallet.ScreenshotExample') }}</div>
              <div class="demo_image__preview">
                <el-image
                  style="width: 150px; height: 150px"
                  :src="url"
                  :preview-src-list="srcList">
                </el-image>
              </div>
            </div>
          </div>
          <div class="account">
            <span>{{ $t('wallet.Bank1') }}</span>
            <input
              type="text"
              v-model="Memo"
              :placeholder="$t('wallet.PleaseMessage')"
              name=""
              id="" />
          </div>
          <div class="message"></div>
          <div class="btn2">
            <button class="cancel comBtn border-col5 col-col5" @click="off">
              {{ $t('wallet.cancel') }}
            </button>
            <button class="ascertain comBtn btn-bg0" @click="ascertain">
              {{ $t('wallet.SubmitBtn') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹出框 -->
    <div class="pay_pal" v-if="payPal">
      <div class="pay_content">
        <p class="pay_title"><span @click="centers">
          <i class="el-icon-close"></i>
        </span></p>
        <paypal
          :money="ptotalAmount"
          :totalmoney="prechargeAmount"
          style="width: 50px; margin-top: -50px; margin-left: 80px"
        ></paypal>
      </div>
    </div>
    <div>
      <AirWallet ref="child" :money="ptotalAmount" :totalmoney="prechargeAmount"></AirWallet>
    </div>
    <!-- 提现弹出框 -->
    <WithdrawalDialog ref="withdrawalDialog" v-if="OpenWithdrawalDialog" @close="CloseWithdrawalDialog"/>
  </div>
</template>
<script>
import paypal from '@/components/paypal.vue'
import AirWallet from '@/components/AirWallet.vue'
import WithdrawalDialog from './WithdrawalDialog.vue'
import ImgMode from '../../../assets/imgs/Bank-Transfer/ZhuanZhangMode.jpg'
import { mapState } from 'vuex'
import { getFrom } from '@/utils/tools'
import { baseApiHost } from '../../../utils/env'
export default {
  data: () => {
    return {
      action: baseApiHost() + '/api/File/UploadImgage',
      baseApiHost: baseApiHost,
      cards: {
        0: require('@/assets/imgs/Bank-Transfer/pay0.png'),
        1: require('@/assets/imgs/Bank-Transfer/pay1.png'),
        11: require('@/assets/imgs/Bank-Transfer/pay13.png'),
        3: require('@/assets/imgs/Bank-Transfer/pay3.png'),
      },
      formLink: getFrom(),
      curentPayType: 1,
      page: 'wallet',
      banner: '',
      itemname: '',
      activeName: 'first', //标签栏切换
      payPal: false,
      DisPlayAirWallet: false,
      balance: '865.26', //账户余额
      payList: [], //支付方式列表
      payMoney: '0',
      value: '',
      selectPayType: 1, //选中的支付方式
      checked: false, //已读
      visa: false, //visa支付弹窗
      dialogImageUrl: '', //上传图片地址
      dialogVisible: false, //上传图片弹
      money1: '00', //金额整数
      money2: '00', //金额小数
      Memo: '', // 客户备注
      headersaa: {
        Authorization: 'Bearer ' + sessionStorage.getItem('Authorization')
      }, // token
      ptotalAmount: 0,
      prechargeAmount: 0,
      url: ImgMode,
      srcList: [ImgMode],
      displayCredits: false,
      CreditCoinArray: [],
      paymentList: [],
      OpenWithdrawalDialog: false,
      CenterMessage: [],
      CenterList: []
    }
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  components: {
    paypal,
    AirWallet,
    WithdrawalDialog
  },
  computed: {
    ...mapState(['theme', 'userInfo']),
    isFree() {
      return this.selectPayType === 0 || this.selectPayType === 11;
    },
    fee: function () {
      if (!this.payList) {
        return 0
      }
      if (this.selectPayType == 0 || this.selectPayType == 11) {
        return
      }
      if (this.payMoney == 0) {
        return 0
      }
      let num = Number(this.payMoney) + Number(0.3)
      if (this.selectPayType == 5) {
        num = Number(this.payMoney) + Number(0.35)
      }
      var curPayItem
      for (var i = 0; i < this.payList.length; i++) {
        if (this.payList[i].PayType == this.selectPayType) {
          curPayItem = this.payList[i]
          break
        }
      }
      let num1 = parseFloat(num / ((100 - curPayItem.FeeRate) / 100).toFixed(4))
      let s2 = num1.toString()
      return parseFloat(Number(s2) - Number(this.payMoney)).toFixed(2)
    },
    totalAmount: function () {
      if (this.selectPayType == 0 || this.selectPayType == 11) {
        return parseFloat(this.payMoney)
      }
      if (this.payMoney == 0) {
        return parseFloat(this.payMoney)
      }
      return (parseFloat(this.payMoney) + parseFloat(this.fee)).toFixed(2)
    }
  },
  mounted() {
    this.banner = this.$t('wallet.bannername')
    this.itemname = this.$t('wallet.itemname')
    this.getData()
    this.menterData()
    this.GetmenterData()
    this.getAgentData()
  },
  methods: {
    AgentData() {
      let that = this;
      this.dispatch('center/GetAgentByIdCr', {
          id: this.CenterMessage.AgentId,
      })
        .then((res) => {
          if (res.Success == false) {
          } else {
            that.CenterList = res.Result;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAgentData() {
      let that = this;
      this.dispatch('center/messageCr')
        .then((res) => {
          that.CenterMessage = res.Result;
          that.rawData = { ...res.Result };
          this.AgentData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    OpenWithdrawalDialogBtn() { /* 提现弹窗 */
      this.OpenWithdrawalDialog = true
      this.$nextTick(() => {
        this.$refs.withdrawalDialog.WithdrawalInformation();
      });
    },
    CloseWithdrawalDialog() {
      this.OpenWithdrawalDialog = false
    },
    handleSelectChange(newVal) {
      this.selectedOption = newVal
    },
    GetmenterData() { /* 用户基本信息 */
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.paymentList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    changePay(index, value) { // 切换支付方式
      this.payList.map((item) => (item.checked = false))
      this.selectPayType = this.payList[index].PayType //  取消选中时 也是当前的支付方式
      this.payList[index].checked = value
    },
    enter: function (e) { /* 展示信用币使用内容 */
      this.displayCredits = true
    },
    leave: function (e) {
      this.displayCredits = false
    },
    getData() {  // 获取支付方式列表
      let that = this
      that.dispatch('wallet/GetPaymentTypesWa')
        .then((res) => {
          that.payList = res.Result ?? []
          that.payList.map((item) => (item.checked = false))
          this.payList[0].checked = true
          this.selectPayType = that.payList.find((item) => item.checked === true).PayType
        })
        .catch((err) => {
          console.log(err)
        })
    },
    menterData() { // 获取账户价格
      let that = this
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          that.CreditCoinArray = res.Result
          that.resetMoney(res.Result.Balance)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    resetMoney(money) {
      let arr = money.toString().split('.')
      if (arr.length > 1) {
        this.money1 = arr[0]
        this.money2 = arr[1]
      } else {
        this.money1 = arr[0]
        this.money2 = '00'
      }
    },
    setPay(index) { //切换支付方式
      this.selectPayType = this.payList[index].PayType
    },
    confirm(item) {  // 开始支付
      let that = this
      if (item == 0) {
        if (this.checked == false) {
          return this.$message.error(this.$t('wallet.pleaseConditions'))
        }
        if (this.payMoney < 500) {
          return this.$message.error(this.$t('wallet.PaymentDollars'))
        }
        this.visa = true
      } else {
        this.visa = false
      }
      if (item == 5) {
        if (this.checked == false) {
          return this.$message.error(this.$t('wallet.pleaseConditions'))
        }
        if (this.payMoney <= 0) {
          return this.$message.error(this.$t('wallet.PaymentAmount'))
        }
        let that = this
        that.dispatch('wallet/CalculateFeeAmountWa', {
            RechargeAmount: that.payMoney,
            PayType: that.selectPayType
          })
          .then((res) => {
            if (res.Success) {
              that.ptotalAmount = res.Result.TotalAmount
              that.prechargeAmount = res.Result.RechargeAmount
              setTimeout(() => {
                that.$refs.child.converBtn()
              }, 500)
            } else {
              that.$message.error(res.ErrMsg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
      if (item == 3) {
        if (this.checked == false) {
          return this.$message.error(this.$t('wallet.pleaseConditions'))
        }
        if (this.payMoney <= 0) {
          return this.$message.error(this.$t('wallet.PaymentAmount'))
        }
        this.walletData()
      }
      if (item == 4) {
        if (this.payMoney < 100) {
          return this.$message.error(this.$t('wallet.PaymentLeast'))
        }
        this.CreditCard()
      }
      if (item == 1) {
        if (this.checked == false) {
          return this.$message.error(this.$t('wallet.pleaseConditions'))
        }
        if (this.payMoney <= 0) {
          return this.$message.error(this.$t('wallet.PaymentAmount'))
        }
        that.dispatch('wallet/CalculateFeeAmountWa', {
            RechargeAmount: that.payMoney,
            PayType: that.payList.find((item) => item.PayType === that.selectPayType).PayType
          })
          .then((res) => {
            if (res.Success) {
              that.ptotalAmount = res.Result.TotalAmount
              that.prechargeAmount = res.Result.RechargeAmount
              that.payPal = true
            } else {
              that.$message.error(res.ErrMsg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.payPal = false
      }
    },
    centers() {
      this.payPal = false
    },
    walletData() {  // Stripe创建支付 (Auth)
      if (this.checked == false) {
        return
      }
      let that = this
      that.dispatch('wallet/CreateCheckoutWa', {
          Amount: Number(this.payMoney),
          Currency: 'USD'
        })
        .then((res) => {
          if (res.Success == true) {
            window.open(res.ResData.Item2)
            this.open()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    open() {  // 弹出框
      this.$confirm('Please complete the payment at the new window', 'Have problem?', {
        confirmButtonText: 'Recharge completed',
        cancelButtonText: 'Have problem'
      })
        .then(() => { //查看充值记录
          this.$router.push({
            name: 'Top Up History',
            params: {
              type: 'TopUpHistory'
            }
          })
        })
        .catch(() => { //查看消费记录
          this.$router.push({
            name: 'Top Up History',
            params: {
              type: 'TopUpHistory'
            }
          })
        })
    },
    recharge() {  //查看充值记录
      this.$router.push({
        name: 'Top Up History',
        params: {
          type: 'TopUpHistory'
        }
      })
    },
    records() { //查看消费记录
      this.$router.push({
        name: 'Top Up History',
        params: {
          type: 'exoenditure'
        }
      })
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    handleAvatarSuccess(res, file) {
      this.getBase64(file.raw).then((res) => {
        this.dialogImageUrl = res
      })
    },
    handleRemove(file, fileList) { //删除图片
      file.url = ''
      this.dialogImageUrl = ''
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    off() {
      this.dialogImageUrl = ''
      this.Memo = ''
      this.visa = false
    },
    setMin(e) { //防止输入框清空出现价格计算错误
      if (e.target.value == '') {
        this.payMoney = 0
      } else {
        this.payMoney = e.target.value
      }
    },
    ascertain() { //  BankTransfer 支付方式
      if (this.dialogImageUrl == '') {
        return this.$message.error(this.$t('wallet.PleaseConfirming'))
      }
      let that = this
      that.dispatch('wallet/SubmitBankTransferWa', {
          RechargeAmount: this.payMoney,
          AttachPic: this.dialogImageUrl,
          Memo: this.Memo,
          MemberId: this.userInfo.Id
        })
        .then((res) => {
          if (res.Success == true) {
            this.$message.success(this.$t('wallet.Successful'))
          } else {
            this.$message.error(res.ErrMsg)
          }
          this.dialogImageUrl = ''
          this.Memo = ''
          this.visa = false
        })
        .catch((err) => {
          console.log(err)
        })
    },
    ToFaq() {
      this.$router.push('/FAQ')
    },
    CreditCard() {  /* 钱海信用卡支付方式 */
      this.dispatch('wallet/SubmitOceanPayWa', {
        RechargeAmount: this.payMoney,
        MemberId: this.userInfo.Id
      })
      .then((res) => {
        if (res.Success == true) {
          this.$router.push({
            path: '/CreditLoading',
            query: {
              rechargeNo: res.Result.RechargeNo
            }
          })
        } else {
          this.$message.error(res.ErrMsg)
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
<style>
.el-message-box__header .el-message-box__headerbtn {
  display: none;
}
.el-message-box__btns .el-button--primary {
  background: #fff !important;
  border: 1px solid #dcdfe6 !important;
  color: #606266 !important;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
</style>
<style scoped lang="scss">
.particulars {
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: #eff1f3;
  min-width: 1320px;
  padding-bottom: 32px;
  /*overflow-x: scroll;*/
  font-family: 'Regular';
}
.activeName {
  width: 1320px;
  border-radius: 10px;
  line-height: 20px;
  position: relative;
}
.CheckAgreement { /* 条款协议 */
  text-align: center;
  margin-top: 20px;
}
.Account {
  margin-left: 11px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 60px;
}
.GreenPCB { /* tab栏切换 */
  width: 1320px;
  margin-left: 20px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
}
::v-deep .el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 146px;
  height: 146px;
  line-height: 146px;
  vertical-align: top;
}
.btn1 {
  display: inline-block;
  height: 149px;
  line-height: 149px;
  border-bottom-color: #fff !important;
  background-color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 100px; /*测试库样式*/
  button {
    width: 230px;
    height: 140px;
    border-radius: 10px;
    padding: 0;
    margin-left: 51px !important;
    border: none;
    background: #fff;
    font-size: 18px;
  }
  img {
    width: 200px;
    height: 130px;
  }
}
::v-deep .el-button.Transfer:focus,
.el-button.Transfer:hover,
.selectedType.Transfer {
  transform: scale(1.05);
}
.btn1 button:hover {
  transform: scale(1.1);
}
.btn1:nth-child(4) {
  width: 380px;
}
.btn1 button img {
  vertical-align: middle;
  margin-right: -5px;
}
.btn_payList {
  display: inline-block;
  height: 149px;
  line-height: 149px;
  border-bottom-color: #fff !important;
  background-color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-left: 230px; /*正式库样式*/
  button {
    width: 230px;
    height: 140px;
    border-radius: 10px;
    padding: 0;
    margin-left: 51px !important;
    border: none;
    background: #fff;
  }
  img {
    width: 200px;
    height: 130px;
  }
}
::v-deep .el-button.Transfer:focus,
.el-button.Transfer:hover,
.selectedType.Transfer {
  transform: scale(1.05);
}
.btn_payList button {
  font-size: 18px;
}
.btn_payList button:hover {
  transform: scale(1.1);
}
.btn_payList:nth-child(4) {
  width: 380px;
}
.btn_payList button img {
  vertical-align: middle;
  margin-right: -5px;
}
.money {
  background: none;
  width: 80%;
  color: #ff7519;
}
.TotalAmount {
  display: inline-block;
  margin-top: 32px;
  margin-left: 135px;
  height: 46px;
  line-height: 46px;
  span {
    font-size: 18px;
    font-weight: 400;
    color: #585858;
  }
  .price-list {
    display: inline-block;
    width: 107px;
    height: 46px;
    border-radius: 5px;
    text-align: center;
    margin-left: 43px;
  }
  .price-list span {
    font-size: 18px;
    font-weight: 400;
    color: #ff7519;
  }
}
.voucher-center {
  p {
    font-size: 14px;
    font-weight: 400;
    color: #c2c2c2;
  }
  p:nth-child(2) {
    font-size: 16px;
    font-weight: 600;
    color: red;
    margin-top: 5px;
    margin-left: 61px;
  }
}
.checked {
  font-size: 14px;
  font-weight: 400;
  color: #585858;
  margin-left: 64px;
  margin-top: 24px;
}
.Confirm button {
  width: 200px;
  height: 46px;
  background: #93df89;
  border-radius: 50px;
  border: 0;
  margin-top: 30px;
  margin-left: 550px;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 18px;
  &:hover {
    background: #bff4b8;
    color: #fff;
  }
}
.recharge {
  height: 760px !important;
  background-color: #ffffff !important;
  border-radius: 10px !important;
}
/* 填写信息 */
.paybox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .pay_content {
    width: 1200px;
    background: #fff;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    overflow: hidden;
    position: absolute;
    left: calc(50% - 550px);
    top: calc(50% - 320px);
    padding-bottom: 15px;
  }
  .pay_header_title {
    width: 100%;
    height: 35px;
    line-height: 35px;
    font-size: 14px;
    font-weight: 500;
    color: #000000;
    padding-left: 43px;
    background: #ffffff;
    span {
      width: 12px;
      height: 12px;
      display: block;
      background: url('../../../assets/imgs/close.png') no-repeat;
      background-size: cover;
      -webkit-background-size: cover;
      float: right;
      margin-top: 15px;
      cursor: pointer;
      margin-right: 30px;
    }
  }
  .pay_items {
    width: 100%;
    height: 240px;
    background: #fff;
    border-bottom: 2px solid #f2f2f2;
    padding-top: 5px;
  }
  .account-information {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding-left: 43px;
    margin-top: -6px;
  }
  .pay_item_word {
    width: 100%;
    height: 35px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #585858;
  }
  .pay_left {
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    margin-left: 221px;
    width: 500px;
    padding: 0;
    margin-top: 10px;
  }
  .pay_right {
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    margin-top: 10px;
  }
  .transfer { /* transfer转账记录 */
    width: 100%;
    background: #fff;
    padding-top: 5px;
    .slip {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      margin-left: 44px;
    }
  }
  .price {
    margin-top: 5px;
    margin-left: 222px;
    span:nth-child(1) {
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
    span:nth-child(2) {
      font-size: 22px;
      font-weight: 500;
      color: #ac1010;
      margin-left: 47px;
    }
  }
  .ImgBigBox {
    width: 100%;
    display: flex;
  }
  .upload {
    margin-left: 222px;
    margin-top: 10px;
    width: 500px;
  }
  .ScreenshotTitle {
    margin-top: -28px;
    font-size: 16px;
  }
  .account {
    margin-left: 222px;
    margin-top: 10px;
    span {
      display: inline-block;
      height: 35px;
      line-height: 35px;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
    }
    input {
      display: inline-block;
      width: 520px;
      height: 35px;
      background: #ffffff;
      border: 1px solid #878787;
      border-radius: 24px;
      font-size: 14px;
      margin-left: 41px;
      padding-left: 52px;
    }
  }
  .message {
    margin-left: 221px;
    margin-top: 10px;
    span {
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      opacity: 0.6;
    }
  }
  .btn2 {
    margin-left: 370px;
    margin-top: 20px;
    .cancel {
      width: 150px;
      height: 40px;
      border-style: solid;
      border-width: 2px;
    }
    .ascertain {
      width: 150px;
      height: 40px;
      margin-left: 130px;
    }
  }
}
.pleaseagent_word {
  text-align: center;
  margin-top: -60px;
  p {
    font-family: 'Regular';
    font-size: 18px;
    font-weight: 400;
  }
}
.pleaseagent_img {
  text-align: center;
  margin-top: 50px;
  cursor: pointer;
  img {
    width: 80px;
    height: 80px;
  }
}
.pay_pal { /* paypal支付弹出框 */
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background: rgba(0, 0, 0, 0.6);
  .pay_content {
    width: 350px;
    height: 150px;
    background: #fff;
    box-shadow: 0px 0px 49px 0px rgba(0, 0, 0, 0.14);
    border-radius: 20px;
    position: absolute;
    left: calc(50% - 200px);
    top: calc(50% - 150px);
    padding-bottom: 32px;
  }
  .pay_title {
    width: 100%;
    height: 40px;
    line-height: 45px;
    font-size: 18px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
    font-weight: 500;
    color: #000000;
    padding-right: 20px;
    background: #ffffff;
    border-bottom: 1px solid #cccccc;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: right;
  }
}
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex_start {
  justify-content: flex-start;
}
.activeName_big_box {
  @extend .flex;
  justify-content: flex-start;
  .part {
    width: 440px;
    height: 380px;
    background: #ffffff;
    border-radius: 10px;
    @extend .flex;
    margin-right: 40px;
    flex-direction: column;
    &:first-child {
      background: #15104b;
      background-image: url('http://sumaieshop.oss-cn-hangzhou.aliyuncs.com/web/1715934649028_part2.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  .part1 {
    justify-content: space-between;
    padding-left: 40px;
    padding: 30px 40px;
    color: #ffffff;
    position: relative;
    .top_account {
      margin-left: -110px;
    }
    .wallet_box_money {
      margin-top: -50px;
    }
    .sum {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 15px;
      span {
        &:first-child {
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
    .Ucredits {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      span {
        margin-right: 5px;
      }
    }
    .credit_limit {
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      span {
        margin-right: 5px;
      }
    }
    .btn {
      width: 100%;
      position: relative;
      @extend .flex;
      button {
        width: 160px;
        border: none;
        &:first-child {
          margin-right: 30px;
        }
      }
      .OpenWithdrawal {
        position: absolute;
        top: -100px;
        left: 100px;
      }
      .rechargeBtn {
        position: absolute;
        bottom: 0px;
        left: 0px;
      }
      .recordsBtn {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
    .creditsBox {
      background: #ffffff;
      border-radius: 10px;
      position: absolute;
      width: 550px;
      line-height: 18px;
      padding: 15px;
      left: 60%;
      top: 15%;
      font-size: 14px;
      color: #000000;
      z-index: 9;
      p {
        margin-bottom: 5px;
        word-break: normal;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .part2 {
    justify-content: space-around;
    ::v-deep .el-checkbox__input {
      margin-top: -25px;
      margin-right: 10px;
      &.is-checked {
        .el-checkbox__inner {
          background-color: #3f6aff;
          border-color: #3f6aff;
        }
      }
    }
    .card {
      width: 260px;
      height: 50px;
      background: #d3dfff;
      border-radius: 25px 25px 25px 25px;
      background-repeat: no-repeat;
      background-position: center;
      @extend .flex;
      img {
        height: 80%;
      }
    }
    .choosed {
      background: #3f6aff;
    }
  }
  .part3 {
    flex-direction: column;
    .item {
      width: 100%;
      margin-bottom: 30px;
      @extend .flex;
      .left {
        width: 50%;
        @extend .flex;
        justify-content: flex-end;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin-right: 20px;

        &.bold {
          font-size: 20px;
          font-weight: 500;
        }
      }
      .right {
        @extend .flex;
        justify-content: flex-start;
        // width: 40%;
        width: 50%;
        font-size: 14px;
        font-weight: 400;
        color: #ff7519;
        .selectedDatr {
          color: red;
          font-weight: 900;
          font-size: 16px;
        }
        .price-list {
          @extend .flex;
          width: 90px;
          height: 28px;
          border-radius: 20px;
          border: 1px solid #e5e5e5;
          input {
            width: calc(100% - 20px);
          }
        }
      }
    }
    .payBtn {
      button {
        width: 216px;
        margin-top: 15px;
      }
    }
  }
}
::v-deep .el-checkbox__input {
  &.is-checked {
    .el-checkbox__inner {
      background-color: #3f6aff;
      border-color: #3f6aff;
    }
  }
}
</style>
