var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "slider" }, [
    _c("div", { staticClass: "slider-header" }, [
      _c("a", { staticClass: "icon", attrs: { href: "", target: "_self" } }, [
        _c("img", { attrs: { src: _vm.logoImg[_vm.formLink], alt: "" } }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "silder-menu" },
      [
        _c(
          "el-menu",
          {
            staticClass: "el-menu-vertical",
            attrs: {
              router: true,
              "background-color": "#15104B",
              "default-active": _vm.defaultActive,
              "text-color": "#ABACC3",
              "active-text-color": "#fff",
            },
            on: { open: _vm.handleOpen, close: _vm.handleClose },
          },
          [_c("MenuItem", { attrs: { route: _vm.routesInfo } })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }