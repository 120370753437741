<template>
  <div class="header">
    <div
      class="headerContent"
      :class="[BindStore ? 'HeaderTitle' : 'HeaderTitleTop', 'headerCommon']"
    >
      <div class="breadContent">
        <breadCrumb class="breadCrumb"></breadCrumb>
      </div>
      <!-- 联系业务员文案 -->
      <div class="multitudinous_icon">
        <!-- <div class="exhibition" @click="ExhibitionBtn">
          <p>Exhibition</p>
        </div> -->
        <!-- v-if="ShowNYPayment" -->
        <div class="nypayment-btn" @click="go2NYPayment">
          <p v-if="!isLoading">NY Payment</p>
          <p v-else>NY Payment<i class="el-icon-loading"></i></p>
        </div>
        <div class="UsaDropProductBtn" @click="UsaDropProductBtn">
          <p>USAdrop Products</p>
        </div>
        <div class="Co_Funder" @click="CoFunderBtn">
          <p>Co-Founder</p>
        </div>
        <div v-if="showAdsAgency" class="Payment" @click="AdsAgencyBtn">
          <p>Ads-agency</p>
        </div>
        <div @click="toggleNotice" class="SliceSmallBell_img" v-if="CenterMessage.GradeId == 1">
          <div><img src="@/assets/imgs/SideBar/SliceSmallBell.png" alt="" /></div>
          <div
            v-if="!!memberVo.LastedNews && !memberVo.LastedNews.IsExpire"
            class="toggleNoticeBlock"
          ></div>
        </div>
        <div
          @click="toggleNotice"
          class="SliceSmallBell_img_member"
          v-if="CenterMessage.GradeId == 3 || CenterMessage.GradeId == 2"
        >
          <div><img src="@/assets/imgs/SideBar/SliceSmallBell.png" alt="" /></div>
          <div
            v-if="!!memberVo.LastedNews && !memberVo.LastedNews.IsExpire"
            class="toggleNoticeBlock"
          ></div>
        </div>
        <div
          class="SliceProfile_img_GradeIdOne"
          @click="ProfileBtn"
          v-if="CenterMessage.GradeId == 1"
        >
          <img
            class="avater"
            :src="
              avater
                ? baseApiHost + avater
                : require('@/assets/imgs/Bank-Transfer/headportrait.png')
            "
            alt=""
          />
        </div>
        <div
          class="SliceProfile_GradeId_img"
          @click="ProfileBtn"
          v-if="CenterMessage.GradeId == 2"
          @mouseenter="onMouseOver"
        >
          <img
            class="avater"
            :src="
              avater
                ? baseApiHost + avater
                : require('@/assets/imgs/Bank-Transfer/headportrait.png')
            "
            alt=""
          />
        </div>
        <div
          class="SliceProfile_img"
          @click="ProfileBtn"
          v-if="CenterMessage.GradeId == 3"
          @mouseenter="onMouseOverSVIP"
        >
          <img
            class="avater"
            :src="
              avater
                ? baseApiHost + avater
                : require('@/assets/imgs/Bank-Transfer/headportrait.png')
            "
            alt=""
          />
        </div>
        <div class="el_menu" trigger="click">
          <el-dropdown>
            <p class="el-dropdown-link">
              {{ userInfo.RealName }}<i class="el-icon-caret-bottom el-icon--right"></i>
            </p>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="linkToRouter('wallet')">
                <img src="@/assets/imgs/SideBar/wallet-right-menu.png" alt="" />
                <span>Wallet</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="linkToRouter('affiliate')">
                <img src="@/assets/imgs/SideBar/Affiliate-right-menu.png" alt="" />
                <span class="Walletword">Affiliate</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="linkToRouter('Video')">
                <img src="@/assets/imgs/SideBar/video-right-menu.png" alt="" />
                <span class="Walletword">Video</span>
              </el-dropdown-item>
              <!-- <el-dropdown-item @click.native="linkToRouter('Exhibition')">
                <img src="@/assets/imgs/SideBar/exhibition-right-menu.png" alt="" />
                <span class="Walletword">Exhibition</span>
              </el-dropdown-item> -->
              <el-dropdown-item @click.native="linkToRouter('HelpCenter')">
                <img src="@/assets/imgs/SideBar/helpcenter-right-menu.png" alt="" />
                <span class="Walletword">Help Center</span>
              </el-dropdown-item>
              <!-- sourcing history -->
              <el-dropdown-item @click.native="linkToRouter('sourcing-history')">
                <img src="@/assets/imgs/SideBar/sourcing.png" alt="" />
                <span class="Walletword">Sourcing History</span>
              </el-dropdown-item>
              <!-- logout -->
              <el-dropdown-item @click.native="linkToRouter('login')" class="dividedDropItem">
                <img src="@/assets/imgs/SideBar/logout-right-menu.png" alt="" />
                <span class="Walletword">Log Out</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <noticeCom :notice="isNotice"></noticeCom>
        <!-- 头部导航划过展示会员弹窗 -->
        <div class="CanCelMemberPop">
          <!-- VIP -->
          <template v-if="ShowVIPPop">
            <div class="VIPBox" v-if="CenterMessage.GradeId == 2" @mouseleave="onMouseOut">
              <div class="VIPBox_title">
                <p>
                  <img src="../../assets/imgs/NewLogo/MemberVIPSlice16.png" alt="" />
                </p>
                <p>{{ $t('HeaderMember.Professional') }}</p>
              </div>
              <div class="HeaderMember_membershipExpire">
                <p>{{ $t('HeaderMember.membershipExpire') }}</p>
              </div>
              <div class="memberVo_EffectiveEndDate_box">
                <div class="memberVo_EffectiveEndDate">
                  <p>{{ memberVo.EffectiveEndDate }}.</p>
                </div>
                <div class="HeaderMember_Membership">
                  &nbsp;<span>{{ $t('HeaderMember.Membership') }}</span> &nbsp;<span>{{
                    $t('HeaderMember.willBeRenewed')
                  }}</span>
                </div>
              </div>
              <div class="Automatically_uponExpiration">
                <p>{{ $t('HeaderMember.Automatically') }}</p>
                <p>{{ $t('HeaderMember.uponExpiration') }}</p>
              </div>
              <div class="HeaderMember_Downgrade_btn" v-if="MenterDataList.IsAdmin == true">
                <el-button class="comBtn btn-bg0" @click="OpenShowMemberPopVIPBox">{{
                  $t('HeaderMember.Downgrade')
                }}</el-button>
              </div>
            </div>
          </template>
          <!-- SVIP -->
          <template v-if="ShowSVIPPop">
            <div class="VIPBox" v-if="CenterMessage.GradeId == 3" @mouseleave="onMouseSVIPout">
              <div class="VIPBox_title">
                <p>
                  <img src="../../assets/imgs/NewLogo/MemberSVIPSlice16.png" alt="" />
                </p>
                <p>{{ $t('HeaderMember.Premium') }}</p>
              </div>
              <div class="HeaderMember_membershipExpire">
                <p>{{ $t('HeaderMember.membershipExpire') }}</p>
              </div>
              <div class="memberVo_EffectiveEndDate_box">
                <div class="memberVo_EffectiveEndDate_SVIP">
                  <p>{{ memberVo.EffectiveEndDate }}.</p>
                </div>
                <div class="HeaderMember_Membership_SVIP">
                  &nbsp;<span>{{ $t('HeaderMember.Membership') }}</span> &nbsp;<span>{{
                    $t('HeaderMember.willBeRenewed')
                  }}</span>
                </div>
              </div>
              <div class="Automatically_uponExpiration_SVIP">
                <p>{{ $t('HeaderMember.Automatically') }}</p>
                <p>{{ $t('HeaderMember.uponExpiration') }}</p>
              </div>
              <div class="HeaderMember_Downgrade_btn" v-if="MenterDataList.IsAdmin == true">
                <el-button class="comBtn btn-bg0" @click="OpenShowMemberPopSVIPBox">{{
                  $t('HeaderMember.Downgrade')
                }}</el-button>
              </div>
            </div>
          </template>
        </div>
        <!-- 展示会员权益的内容 -->
        <div class="ShowMemberPop">
          <!-- VIP -->
          <div v-if="ShowMemberPopVIPBox">
            <div class="ShowMemberPopVIP_Box" v-if="CenterMessage.GradeId == 2">
              <div class="ShowMemberPopVIP_Content">
                <div class="membershipFee_membershipFollows">
                  <p>{{ $t('HeaderMember.membershipFee') }}</p>
                  <p>{{ $t('HeaderMember.membershipFollows') }}</p>
                </div>
                <div class="VIPMembershipBenefits">
                  <!-- VIP会员权益 -->
                  <div class="memberInfoVIP_box">
                    <div class="BigMemberImgVIPSliceImg">
                      <img src="../../assets/imgs/ProfileSub/BigMemberImgVIPSlice.png" alt="" />
                    </div>
                    <div v-for="(member, i) in memberInfo[1].detail" :key="member.Id">
                      <div class="Winner_Product_Quote">
                        <p class="checkbox" v-if="memberInfo[1].detail[i].value == 'true'">
                          <i class="el-icon-check" style="color: #3c763d"></i>
                        </p>
                        <p v-else class="MemberValue">{{ memberInfo[1].detail[i].value }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- 会员权益说明 -->
                  <div class="MemberBenefitsDescription">
                    <div class="ArrowSliceImg">
                      <img src="../../assets/imgs/ProfileSub/ArrowSlice.png" alt="" />
                    </div>
                    <div v-for="member in memberInfo[1].detail" :key="member.Id">
                      <div class="Winner_Product_Quote">
                        <p class="Winner_Product_Name">{{ member.name }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- 基础会员 -->
                  <div class="BasicMembership">
                    <div class="BigMemberImgVIPSliceImg">
                      <img src="../../assets/imgs/ProfileSub/MemberSliceJiChu1.png" alt="" />
                    </div>
                    <div v-for="member in memberInfo[0].detail" :key="member.GradeName">
                      <div class="Winner_Product_Quote">
                        <p class="checkbox" v-if="member.value == 'true'">
                          <i class="el-icon-check" style="color: #3c763d"></i>
                        </p>
                        <p class="checkbox" v-else-if="member.value == 'false'">
                          <span style="color: #000">--</span>
                        </p>
                        <p v-else class="MemberValue">{{ member.value }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 按钮 -->
                <div class="Back_Downgrade_Btn_Box">
                  <el-button class="HeaderMember_Back" @click="HeaderMemberClose">{{
                    $t('HeaderMember.Back')
                  }}</el-button>
                  <el-button class="HeaderMember_Downgrade" @click="HeaderMemberDowngrade">{{
                    $t('HeaderMember.Downgrade')
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
          <!-- SVIP -->
          <div v-if="ShowMemberPopSVIPBox">
            <div class="ShowMemberPopVIP_Box" v-if="CenterMessage.GradeId == 3">
              <div class="ShowMemberPopVIP_Content">
                <div class="membershipFee_membershipFollows">
                  <p>{{ $t('HeaderMember.membershipFee') }}</p>
                  <p>{{ $t('HeaderMember.membershipFollows') }}</p>
                </div>
                <div class="VIPMembershipBenefits">
                  <!-- SVIP会员权益 -->
                  <div class="memberInfoVIP_box" style="background: #fffbf3">
                    <div class="BigMemberImgVIPSliceImg">
                      <img src="../../assets/imgs/ProfileSub/BigMemberImgSVIPSlice.png" alt="" />
                    </div>
                    <div v-for="(member, i) in memberInfo[1].detail" :key="member.Id">
                      <div class="Winner_Product_Quote">
                        <p class="checkbox" v-if="memberInfo[2].detail[i].value == 'true'">
                          <i class="el-icon-check" style="color: #3c763d"></i>
                        </p>
                        <p v-else class="MemberValue">{{ memberInfo[2].detail[i].value }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- 会员权益说明 -->
                  <div class="MemberBenefitsDescription">
                    <div class="ArrowSliceImg">
                      <img src="../../assets/imgs/ProfileSub/ArrowSlice.png" alt="" />
                    </div>
                    <div v-for="member in memberInfo[1].detail" :key="member.Id">
                      <div class="Winner_Product_Quote">
                        <p class="Winner_Product_Name">{{ member.name }}</p>
                      </div>
                    </div>
                  </div>
                  <!-- 基础会员 -->
                  <div class="BasicMembership">
                    <div class="BigMemberImgVIPSliceImg">
                      <img src="../../assets/imgs/ProfileSub/MemberSliceJiChu1.png" alt="" />
                    </div>
                    <div v-for="member in memberInfo[0].detail" :key="member.GradeName">
                      <div class="Winner_Product_Quote">
                        <p class="checkbox" v-if="member.value == 'true'">
                          <i class="el-icon-check" style="color: #3c763d"></i>
                        </p>
                        <p class="checkbox" v-else-if="member.value == 'false'">
                          <span style="color: #000">--</span>
                        </p>
                        <p v-else class="MemberValue">{{ member.value }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 按钮 -->
                <div class="Back_Downgrade_Btn_Box">
                  <el-button class="HeaderMember_Back" @click="HeaderMemberCloseSVIP">{{
                    $t('HeaderMember.Back')
                  }}</el-button>
                  <el-button class="HeaderMember_Downgrade" @click="HeaderMemberDowngradeSVIP">{{
                    $t('HeaderMember.Downgrade')
                  }}</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 最终取消会员费弹窗 -->
        <div class="cancellation_box" v-if="ShowCancellationBox">
          <!-- VIP -->
          <div class="cancellation_vip_box" v-if="CenterMessage.GradeId == 2">
            <div class="closeBtn" @click="CloseVIP">
              <i class="el-icon-close"></i>
            </div>
            <div class="HeaderMember_YouCancelled">
              <p>{{ $t('HeaderMember.YouCancelled') }}</p>
            </div>
            <div class="membershipFeeYour_thismonthwill">
              <p>{{ $t('HeaderMember.membershipFeeYour') }}</p>
              <p>{{ $t('HeaderMember.thismonthwill') }}</p>
            </div>
            <div class="chargednormal_wewill">
              <p>{{ $t('HeaderMember.chargednormal') }}</p>
              <p>{{ $t('HeaderMember.wewill') }}</p>
            </div>
            <div class="HeaderMember_automaticallyfees">
              <p>{{ $t('HeaderMember.automaticallyfees') }}</p>
            </div>
            <div class="HeaderMember_nextmonth">
              <p>{{ $t('HeaderMember.nextmonth') }}</p>
            </div>
            <div class="HeaderMember_OK">
              <el-button class="HeaderMember_OK_Btn" @click="handleConfirm">{{
                $t('HeaderMember.OK')
              }}</el-button>
            </div>
          </div>
          <!-- SVIP -->
          <div class="cancellation_Svip_box" v-if="CenterMessage.GradeId == 3">
            <div class="closeBtn" @click="CloseSVIP">
              <i class="el-icon-close"></i>
            </div>
            <div class="HeaderMember_YouCancelled">
              <p>{{ $t('HeaderMember.YouCancelled') }}</p>
            </div>
            <div class="membershipFeeYour_thismonthwill">
              <p>{{ $t('HeaderMember.membershipFeeYour') }}</p>
              <p>{{ $t('HeaderMember.thismonthwill') }}</p>
            </div>
            <div class="chargednormal_wewill">
              <p>{{ $t('HeaderMember.chargednormal') }}</p>
              <p>{{ $t('HeaderMember.wewill') }}</p>
            </div>
            <div class="HeaderMember_automaticallyfees">
              <p>{{ $t('HeaderMember.automaticallyfees') }}</p>
            </div>
            <div class="HeaderMember_nextmonth">
              <p>{{ $t('HeaderMember.nextmonth') }}</p>
            </div>
            <div class="HeaderMember_OK">
              <el-button class="HeaderMember_OK_Btn" @click="handleConfirm">{{
                $t('HeaderMember.OK')
              }}</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import noticeCom from '@/components/notification.vue'
import { mapState } from 'vuex'
import breadCrumb from '@/components/breadCrumb.vue'
import { baseApiHost } from '@/utils/env'
export default {
  components: {
    noticeCom,
    breadCrumb
  },
  data() {
    return {
      baseApiHost: baseApiHost(),
      CenterMessage: {},
      isNotice: false,
      BindStore: false, //是否绑定店铺
      memberVo: {
        LastedNews: {
          IsExpire: true
        }
      },
      ShowVIPPop: false,
      ShowSVIPPop: false,
      ShowMemberPopVIPBox: false,
      ShowMemberPopSVIPBox: false,
      ShowCancellationBox: false,
      ShowNYPayment: false,
      memberInfo: {},
      MenterDataList: [],
      activeIndex: '1-1',
      showAdsAgency: false,
      isLoading: false,
      paymentList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'theme', 'avater'])
  },
  created() {
    this.dispatch = this.$store.dispatch
  },
  mounted() {
    /* const currentEmail = localStorage.getItem('email') */
    /* const USAdrop_user = JSON.parse(sessionStorage.getItem("USAdrop_user"));
    if (USAdrop_user && (USAdrop_user.email === 'leoliao@hotmail.com' || USAdrop_user.email === 'andyzhou@hotmail.com')) {
      this.ShowNYPayment = true;
    } else {
      this.ShowNYPayment = false;
    } */
    this.CheckGuide() // 是否绑定店铺
    this.dispatch('requestAvater')
    this.getData()
    this.getMemberInfo()
    this.GetMenterData()
    this.menterData()
  },
  methods: {
    go2NYPayment() {
      this.isLoading = true;
      this.dispatch('home/NyTokenHeader')
      .then((res) => {
        const tk = res.Result || ''
        if(res.Result == null) {
          this.$message.error('Your NY account already exists, please use the NY web page to log in')
        } else {
          const nyHome = process.env.VUE_APP_NY_HOME_URL
          window.open(`${nyHome}?from=ud&tk=${tk}`)
        }
      })
      .catch((err) => {
        this.$message.error(res.Msg)
      })
      .finally(() => {
        this.isLoading = false;
      });
    },
    navigateTo() {
      this.$router.push('/Sourcing')
    },
    AdsAgencyBtn() {
      const tk = sessionStorage.getItem('Authorization') || ''
      window.location.href = `${process.env.VUE_APP_ADS_HOST}/wallet/wallet?tk=${tk}`
    },
    ExhibitionBtn() {
      this.$router.push('/ExhibitionTwo')
    },
    CoFunderBtn() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.memberVo = res.Result
          if (res.Result.IsCoFunder == true) {
            const tk = sessionStorage.getItem('Authorization') || ''
            window.open(`${process.env.VUE_APP_FOUNDER_HOST}/home/index?tk=${tk}`)
          } else {
            window.open('https://co-founder.usadrop.com')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    UsaDropProductBtn() {
      const tk = sessionStorage.getItem('Authorization') || ''
      window.open(`${process.env.VUE_APP_UD_SHOP_HOST}?tk=` + tk)
    },
    GetMenterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.MenterDataList = res.Result
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onMouseOver() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.memberVo = res.Result
          if (res.Result.IsSubscription == true) {
            this.ShowVIPPop = true
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onMouseOut() {
      this.ShowVIPPop = false
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          if (res.Result.IsSubscription == true) {
          } else {
            this.ShowVIPPop = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onMouseOverSVIP() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.memberVo = res.Result
          if (res.Result.IsSubscription == true) {
            this.ShowSVIPPop = true
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    onMouseSVIPout() {
      this.ShowSVIPPop = false
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          if (res.Result.IsSubscription == true) {
          } else {
            this.ShowSVIPPop = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    HeaderMemberClose() {
      this.ShowMemberPopVIPBox = false
    },
    HeaderMemberCloseSVIP() {
      this.ShowMemberPopSVIPBox = false
    },
    /* 打开VIP会员权益弹窗 */
    OpenShowMemberPopVIPBox() {
      this.ShowMemberPopVIPBox = true
      setTimeout(() => {
        this.ShowVIPPop = false
      }, 0)
    },
    /* 打开SVIP会员权益弹窗 */
    OpenShowMemberPopSVIPBox() {
      this.ShowMemberPopSVIPBox = true
      setTimeout(() => {
        this.ShowSVIPPop = false
      }, 0)
    },
    HeaderMemberDowngrade() {
      this.ShowCancellationBox = true
      setTimeout(() => {
        this.HeaderMemberClose()
      }, 0)
    },
    HeaderMemberDowngradeSVIP() {
      this.ShowCancellationBox = true
      setTimeout(() => {
        this.HeaderMemberCloseSVIP()
      }, 0)
    },
    CloseVIP() {
      this.ShowCancellationBox = false
    },
    CloseSVIP() {
      this.ShowCancellationBox = false
    },
    // 获取数据
    getData() {
      let that = this
      this.dispatch('center/messageCr')
        .then((res) => {
          that.CenterMessage = res.Result
          that.rawData = { ...res.Result }
          that.imageUrl = that.CenterMessage.Avatar
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 是否绑定店铺 */
    CheckGuide() {
      this.dispatch('guide/checkGuideGu')
        .then((res) => {
          this.BindStoreList = res.Result
          if (res.Result.IsStoreComplete == true) {
            this.BindStoreList = res.Result
            this.BindStore = false
          } else {
            this.BindStore = true
          }
          if (res.Result.More7daysNoStoreOrNoGrade == true) {
            this.ShowLock = true
          } else {
            this.ShowLock = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    toggleNotice() {
      window.location.href = `${window.location.origin}/ImportantNotices`
    },
    ProfileBtn() {
      window.location.href = `${window.location.origin}/ProfileSub`
    },
    SlicelgoutBtn() {
      window.sessionStorage.clear()
      window.location.href = `${window.location.origin}/`
    },
    // 会员过期提醒
    getCurrentTime() {
      //获取当前时间
      let year = new Date().getFullYear()
      let month = new Date().getMonth()
      let day = new Date().getDate()
      let time = new Date(year, month, day + 7)
      let endTime = new Date(this.memberVo.EffectiveEndDate)
      if (time >= endTime) {
      }
    },
    // 会员弹窗提醒
    WannaExpirePopup() {
      this.dispatch('winner/WannaExpirePopupWi')
        .then((res) => {
          if (res.Result == true) {
            this.getCurrentTime()
          } else {
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    linkToRouter(pathName) {
      if (pathName === 'login') {
        setTimeout(() => {
          this.menterData();
        }, 100);
        // 优先判断是否同时包含 0 和 3
        if (this.paymentList.OwnBizTypes.includes(0) && this.paymentList.OwnBizTypes.includes(3)) {
          window.sessionStorage.clear();
          window.location.href = `${window.location.origin}/`;
        } else if (this.paymentList.OwnBizTypes.includes(3)) {
          // 如果仅包含 3，跳转到带查询参数的登录页
          window.sessionStorage.clear();
          window.location.href = `${window.location.origin}/login?from=nypayment`;
        } else {
          // 如果包含 0, 1, 2, 3, 4，跳转到根页面
          window.sessionStorage.clear();
          window.location.href = `${window.location.origin}/`;
        }
      } else {
        this.$router.push(`/${pathName}`);
      }
    },

    /* 会员等级 */
    menterData() {
      this.dispatch('member/GetMemberVoMe')
        .then((res) => {
          this.paymentList = res.Result;
          const OwnBizTypes = res.Result.OwnBizTypes || []
          if (OwnBizTypes.includes(1)) {
            this.showAdsAgency = true;
          } else {
            this.showAdsAgency = false;
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 会员费权益展示 */
    getMemberInfo() {
      let that = this
      that
        .dispatch('member/GetAllGradeListMe')
        .then((res) => {
          if (res.Success) {
            let basic = res.Result
            that.getDetail(basic)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /* 会员费权益展示 */
    getDetail(basic) {
      let that = this
      that
        .dispatch('member/GetGradeSettingsMe')
        .then((res) => {
          if (res.Success) {
          }
          let detail = res.Result
          for (let i = 0; i < basic.length; i++) {
            //格式调整
            basic[i].detail = []
            var vos = detail[i].SettingVos
            for (var j in vos) {
              let row = {}
              row.name = vos[j].Title
              row.value = vos[j].Remarks
              row.ElTooltip = vos[j].Tooltip
              basic[i].detail.push(row)
            }
          }
          that.memberInfo = basic
          that.$nextTick(() => {
            let height = document.getElementsByClassName('main')[0]?.offsetHeight
            this.shadowHeight = height
          })
        })
        .catch((err) => {
          console.log(err)
        })
    },

    /* 取消会员费订阅 */
    handleConfirm() {
      let that = this
      that
        .dispatch('center/CancelSubscriptionCr', {})
        .then((res) => {
          if (res.Success == true) {
            this.ShowCancellationBox = false
            this.ShowVIPPop = false
            this.ShowSVIPPop = false
            this.$message.success({
              message: this.$t('center.CancelledSuccessfully'),
              duration: 5000
            })
          } else {
            this.ShowCancellationBox = false
            this.ShowVIPPop = false
            this.ShowSVIPPop = false
            this.$message.error({
              message: this.$t('center.Failtocancel'),
              duration: 5000
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
<style>
::v-deep .el-submenu__title {
  background-color: transparent !important; /* 设置背景色为透明 */
}
::v-deep .el-submenu__title {
  background-color: transparent !important;
}
</style>
<style lang="scss" scoped>
.dividedDropItem {
  border-top: 1px solid #ebeef5;
}
.el-dropdown-menu__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #15104b;
  font-size: 14px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.el-dropdown-menu__item--divided:before {
  margin: 0;
}
.header {
  width: 100%;
  height: 70px;
  background: #eff1f3;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 100px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: calc(100% - 60px);
    background: #dedede;
    left: 60px;
    bottom: 0;
  }
  .headerContent {
    width: 100%;
    display: flex;
    height: 100%;
    align-items: flex-end;
    justify-content: space-between;
    .breadContent {
      display: flex;
      align-items: flex-end;
      padding-left: 56px;
      height: 100%;
      padding-bottom: 15px;
      ::v-deep .el-breadcrumb {
        min-width: 300px;
      }
    }
  }
}
.el-icon-arrow-down {
  font-size: 12px;
}
.HeaderTitle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.HeaderTitleTop {
  top: 15px;
}
.multitudinous_icon {
  display: flex;
  align-items: center;
  padding-bottom: 11px;
  position: relative;
  .Co_Funder {
    width: 100px;
    margin-right: 30px;
    cursor: pointer;
    p {
      font-size: 16px;
      color: #3f6aff;
    }
  }
  .nypayment-btn {
    width: 150px;

    cursor: pointer;
    p {
      font-size: 16px;
      color: #3f6aff;
    }
  }
  .UsaDropProductBtn {
    width: 150px;
    margin-right: 30px;
    cursor: pointer;
    p {
      font-size: 16px;
      color: #3f6aff;
    }
  }
  .Payment {
    width: 100px;
    margin-right: 30px;
    cursor: pointer;
    p {
      font-size: 16px;
      color: #3f6aff;
    }
  }
  .exhibition {
    width: 100px;
    margin-right: 30px;
    cursor: pointer;
    p:nth-child(1) {
      font-size: 18px;
      color: #d9534f;
      font-weight: 600;
    }
  }
  .el_menu {
    margin-left: 10px;

    ::v-deep .el-dropdown {
      width: 100%;
      height: 24px;
      .el-dropdown-link {
        cursor: pointer;
        color: #15104b;
        font-size: 18px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
      }
    }
  }
}
.SliceSmallBell_img {
  display: flex;
  width: 44px;
  margin-right: 0px;
}
.toggleNoticeBlock {
  width: 5px;
  height: 5px;
  background: red;
  border-radius: 50px;
}
.SliceSmallBell_img img {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.SliceSmallBell_img_member {
  display: flex;
  width: 44px;
  margin-right: 0px;
}
.SliceSmallBell_img_member img {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.SliceProfile_img_GradeIdOne {
  background-image: url('../../assets/imgs/NewLogo/headImgJC.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 57px;
  height: 57px;
  position: relative;
}
.SliceProfile_img_GradeIdOne .avater {
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
}
.SliceProfile_GradeId_img {
  background-image: url('../../assets/imgs/NewLogo/headGroupVIP.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 58px;
  height: 58px;
  position: relative;
}
.SliceProfile_GradeId_img .avater {
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  top: 11px;
  left: 10px;
}
.SliceProfile_img {
  background-image: url('../../assets/imgs/NewLogo/HeadGroupSVIP.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 57px;
  height: 58px;
  position: relative;
}
.SliceProfile_img .avater {
  width: 40px;
  height: 40px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);
  opacity: 1;
  border-radius: 50%;
  margin-right: 5px;
  cursor: pointer;
  position: absolute;
  top: 11px;
  left: 10px;
}
.Slicelgout_img img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.Welcome_Copywriting {
  width: 100px;
  height: 24px;
  line-height: 24px;
  font-size: 20px;
  font-family: Inter-Semi Bold, Inter;
  color: #15104b;
  margin-right: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.VIPBox {
  width: 345px;
  height: 200px;
  background: #ffffff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.16);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  position: absolute;
  top: 43px;
  right: -10px;
  z-index: 99;
}
.VIPBox_title {
  display: flex;
}
.VIPBox_title p:nth-child(1) img {
  margin-left: 45px;
  margin-top: 20px;
}
.VIPBox_title p:nth-child(2) {
  font-size: 20px;
  color: #333333;
  margin-left: 15px;
  margin-top: 28px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_membershipExpire p {
  font-size: 14px;
  color: #333333;
  margin-left: 55px;
  margin-top: 10px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.memberVo_EffectiveEndDate_box {
  display: flex;
}
.memberVo_EffectiveEndDate {
  color: #d14139;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-left: 15px;
}
/* SVIP */
.memberVo_EffectiveEndDate_SVIP {
  color: #fcb12c;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
  margin-left: 15px;
}
.HeaderMember_Membership span:nth-child(1) {
  color: #333333;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_Membership span:nth-child(2) {
  color: #d14139;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* SVIP */
.HeaderMember_Membership_SVIP span:nth-child(1) {
  color: #333333;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_Membership_SVIP span:nth-child(2) {
  color: #fcb12c;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Automatically_uponExpiration {
  display: flex;
  margin-left: 55px;
}
.Automatically_uponExpiration p:nth-child(1) {
  color: #d14139;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Automatically_uponExpiration p:nth-child(2) {
  color: #333333;
  font-size: 14px;
  margin-left: 5px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
/* SVIP */
.Automatically_uponExpiration_SVIP {
  display: flex;
  margin-left: 55px;
}
.Automatically_uponExpiration_SVIP p:nth-child(1) {
  color: #fcb12c;
  font-size: 14px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.Automatically_uponExpiration_SVIP p:nth-child(2) {
  color: #333333;
  font-size: 14px;
  margin-left: 5px;
  font-family: 'Regular';
  -webkit-font-smoothing: antialiased;
}
.HeaderMember_Downgrade_btn {
  text-align: center;
  margin-top: 10px;
}
.el-button {
  border-radius: 50px !important;
}
.ShowMemberPopVIP_Box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.6);
  .ShowMemberPopVIP_Content {
    width: 1110px;
    height: 750px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    margin-left: 430px;
    margin-top: 50px;
    position: relative;
    overflow-x: auto;
    scrollbar-width: none;
    padding-bottom: 25px;
  }
  .closeBtn {
    position: absolute;
    top: 10px;
    right: 30px;
    .el-icon-close {
      font-size: 24px;
    }
  }
  .membershipFee_membershipFollows {
    text-align: center;
  }
  .membershipFee_membershipFollows p:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
    padding-top: 30px;
  }
  .membershipFee_membershipFollows p:nth-child(2) {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }
  .VIPMembershipBenefits {
    display: flex;
  }
  .memberInfoVIP_box {
    width: 260px;
    height: auto;
    background: #fff3f2;
    border-radius: 16px;
    margin-top: 40px;
    margin-left: 115px;
    .BigMemberImgVIPSliceImg img {
      width: 56px;
      height: 56px;
      margin-left: 100px;
      margin-top: 30px;
    }
    .Winner_Product_Quote p {
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      .el-icon-check {
        font-size: 20px;
      }
    }
  }
  .MemberBenefitsDescription {
    width: 260px;
    height: auto;
    margin-top: 40px;
    margin-left: 50px;
    .ArrowSliceImg img {
      width: 160px;
      height: 25px;
      margin-left: 50px;
      margin-top: 50px;
    }
    .Winner_Product_Quote {
      margin-top: 5px;
    }
    .Winner_Product_Quote p {
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-top: 6px;
    }
  }
  /* 基础 */
  .BasicMembership {
    width: 260px;
    height: auto;
    background: #edf4fe;
    border-radius: 16px;
    margin-top: 40px;
    margin-left: 50px;
    .BigMemberImgVIPSliceImg img {
      width: 56px;
      height: 56px;
      margin-left: 100px;
      margin-top: 30px;
    }
    .Winner_Product_Quote p {
      font-size: 14px;
      line-height: 40px;
      text-align: center;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      .el-icon-check {
        font-size: 20px;
      }
    }
  }
  .Back_Downgrade_Btn_Box {
    text-align: center;
  }
  .HeaderMember_Back {
    width: 200px;
    height: 44px;
    background: #ffffff;
    border-radius: 22px 22px 22px 22px;
    opacity: 1;
    border: 2px solid #15104b;
    margin-top: 35px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }
  .HeaderMember_Back:hover {
    background: #15104b;
    color: #ffffff !important;
  }
  .HeaderMember_Downgrade {
    width: 200px;
    height: 44px;
    background: #15104b;
    border-radius: 22px 22px 22px 22px;
    opacity: 1;
    color: #ffffff;
    margin-top: 35px;
    margin-left: 60px;
    font-family: 'Regular';
    -webkit-font-smoothing: antialiased;
  }
  .HeaderMember_Downgrade:hover {
    opacity: 0.93;
    color: #ffffff !important;
  }
}
.cancellation_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: rgba(0, 0, 0, 0.6);
  /* VIP */
  .cancellation_vip_box {
    width: 560px;
    height: 281px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    margin: 15% auto;
    position: relative;
    .closeBtn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 20px;
    }
    .HeaderMember_YouCancelled {
      margin-left: 30px;
      padding-top: 35px;
    }
    .HeaderMember_YouCancelled p {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .membershipFeeYour_thismonthwill {
      margin-left: 30px;
      display: flex;
      margin-top: 5px;
    }
    .membershipFeeYour_thismonthwill p:nth-child(1) {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .membershipFeeYour_thismonthwill p:nth-child(2) {
      color: #d14139;
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-left: 5px;
    }
    .chargednormal_wewill {
      margin-left: 30px;
      display: flex;
      margin-top: 5px;
    }
    .chargednormal_wewill p:nth-child(1) {
      color: #d14139;
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .chargednormal_wewill p:nth-child(2) {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-left: 5px;
    }
    .HeaderMember_automaticallyfees {
      margin-left: 30px;
      margin-top: 5px;
    }
    .HeaderMember_automaticallyfees p {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .HeaderMember_nextmonth {
      margin-left: 30px;
      margin-top: 5px;
    }
    .HeaderMember_nextmonth p {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      color: #2c87fb;
    }
    .HeaderMember_OK {
      text-align: center;
      .el-button {
        width: 200px;
        height: 44px;
        background: #15104b;
        border-radius: 22px 22px 22px 22px;
        opacity: 1;
        color: #ffffff;
        margin-top: 35px;
        font-family: 'Regular';
        -webkit-font-smoothing: antialiased;
      }
    }
  }
  /* SVIP */
  .cancellation_Svip_box {
    width: 560px;
    height: 281px;
    background: #ffffff;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    margin: 15% auto;
    position: relative;
    .closeBtn {
      position: absolute;
      top: 10px;
      right: 20px;
      font-size: 20px;
    }
    .HeaderMember_YouCancelled {
      margin-left: 30px;
      padding-top: 35px;
    }
    .HeaderMember_YouCancelled p {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .membershipFeeYour_thismonthwill {
      margin-left: 30px;
      display: flex;
      margin-top: 5px;
    }
    .membershipFeeYour_thismonthwill p:nth-child(1) {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .membershipFeeYour_thismonthwill p:nth-child(2) {
      color: #fcb12c;
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-left: 5px;
    }
    .chargednormal_wewill {
      margin-left: 30px;
      display: flex;
      margin-top: 5px;
    }
    .chargednormal_wewill p:nth-child(1) {
      color: #fcb12c;
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .chargednormal_wewill p:nth-child(2) {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      margin-left: 5px;
    }
    .HeaderMember_automaticallyfees {
      margin-left: 30px;
      margin-top: 5px;
    }
    .HeaderMember_automaticallyfees p {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
    .HeaderMember_nextmonth {
      margin-left: 30px;
      margin-top: 5px;
    }
    .HeaderMember_nextmonth p {
      font-size: 16px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
      color: #2c87fb;
    }
    .HeaderMember_OK {
      text-align: center;
    }
    .HeaderMember_OK_Btn {
      width: 200px;
      height: 44px;
      background: #15104b;
      border-radius: 22px 22px 22px 22px;
      opacity: 1;
      color: #ffffff;
      margin-top: 35px;
      font-family: 'Regular';
      -webkit-font-smoothing: antialiased;
    }
  }
}
</style>
